import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, useSearchParams } from 'react-router-dom';
// import Timeline from '../Partials/Timeline';
import axios from "axios";
import './Register.scss';
import './style.css';
import { Link } from "react-router-dom";
import { Color } from 'three';
import Sponsors from './Sponsor';



const Heropage = (props) => {




  const [queryParameters] = useSearchParams()









  return (
    <>
    <div className="hero">
      <img src="/l2.jpg" className='background' />
      <img src="/l1.png" className='foreground' />
      <div className="grad"></div>
      <nav>
        <Link to={'/register'}>
        <img src="/Logo.png" className='logo' />
        </Link>
        <div className="navmenu">
          <div className='d-flex'>

          </div>
        </div>
      </nav>
      <div className='titlecont'>
        <img src="/ccs.png" alt="" className='ccs-logo' />
        <h6 className='presents'>PRESENTS</h6>
        <h1 className='title-style'  class="glitch" data-text="RAPTUS">RAPTUS</h1>
        <h1 className='tag-style'> LET THE HEIST BEGIN... </h1>
        

      </div>
      {/* <button style={{background:"none",border:"none",marginTop:"30%",width:"100%",cursor:"pointer",position:'absolute'}} onClick={()=>{console.log("Hi")}}>
          <a class="arrow-icon">
            <span class="left-bar" ></span>
            <span class="right-bar"></span>
          </a>
        </button> */}
        </div>
    </>
  )
}

export default Heropage
