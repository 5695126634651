import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../../../Partials/sidebar/Sidebar'
import Topbar from '../../../Partials/topbar/Topbar'
import { useNavigate } from 'react-router-dom';
import './leaderboard.css'
import { api_url } from '../../../config';

import ReactDOM from 'react-dom';
import Toast from '../../../Partials/notification/toastComponent';
import leaderboardSocket from '../../../leaderboardSocket.js';


export default function Leaderboard() {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [team, setTeam] = useState({});
    const [LB, setLB] = useState([]);
    const [res, setRes] = useState('')
    const navigate = useNavigate();


    const createToast = (message, type, duration = 5000) => {
        const toastContainer = document.querySelector('.notifications');
        if (toastContainer) {
            const toast = <Toast key={Date.now()} message={message} type={type} duration={duration} />;
            ReactDOM.render(toast, toastContainer);
        }
    };


    const getPoints = async () => {
        axios
            .get(api_url + '/verify', {
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.status) {
                    setRes(res.data)
                } else {
                  //  navigate(`/login`);
                }
            });
    }


    const fetchData = async () => {
        try {
            axios
                .get(api_url + '/verify/leaderboard', {
                    withCredentials: true,
                })
                .then((res) => {
                    if (res.data.status) {
                        setLB(res.data.LB);
                        if(res.data.group===0){
                            currentTeam(res.data.LB[(res.data.group)], res.data.TeamName);
                            setLeaderboardData(res.data.LB[(res.data.group)]);
                        }
                        else{
                            currentTeam(res.data.LB[(res.data.group)-1], res.data.TeamName);
                            setLeaderboardData(res.data.LB[(res.data.group)-1]);
                        }
                    }
                })
        }
        catch (error) {
            console.error('Error fetching leaderboard data:', error);
        }
    };



    useEffect(() => {
        fetchData()
        getPoints();
    }, []);


    const currentTeam = (LB, TeamName) => {
            LB.map((e, x) => {
                if (e.TeamName === TeamName) {
                    e.Rank = x + 1;
                    setTeam(e);
                }
            })
    }



    const Onbtn = (i) => {
            setLeaderboardData(LB[i]);
    }



    const handleNotification = (data) => {
        // console.log('Custom notification handler:', data);
        // Handle the received notification in a custom way
    };

    const handleLeaderboardUpdate = (data) => {
        fetchData();
        getPoints();;
    };

    leaderboardSocket(handleNotification, handleLeaderboardUpdate);






    return (
        <div className='wrapper cmapbg'>
            <ul className="notifications"></ul>
            <Sidebar data="3" />
            <div className='content' style={{marginTop:"0%"}}>
                <Topbar res={res} />

                <div className="groupswitch">
                    {LB.map((e, i) => {
                        return(
                            <button className='Submit-btn' onClick={() => Onbtn(i)}>{ `Group ${i + 1}`}</button>
                            )
                    })}
                </div>

                <div className="leaderboardcont">
                    {leaderboardData.map((team, index) => {
                        return(
                            <div className="team">
                            <h5 className="rank">{index + 1}</h5>
                            <h5 className="teamname">{team.TeamName}</h5>
                            <h5 className="pts">{team.Points}</h5>
                            <h5 className="time">{team.TotalTime}</h5>
                        </div>
                            )
                        }
                    )}

                </div>

            </div>
        </div>
    )
}
