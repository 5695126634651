import { useEffect } from "react";
import { io } from 'socket.io-client';
import { api_url } from "./config";

const useSocket = () => {
    useEffect(() => {
        const socket = io(api_url, {
            transports: ['websocket'],
            withCredentials: true,
        });

        const handleSocketMessage = (data) => {
            console.log('Received message: 0', data);
            // Handle the received message here
            alert(data);
        };

        const handleUserConnectionStatus = (data) => {
            console.log('Received user connection status:', data);
          //  alert(data + "1");
            // Handle the received user connection status here
        };

        const handleNotification = (data) => {
            console.log('Received notification:', data);
            alert(data);
            // Handle the received notification here
        };


        socket.on('message', handleSocketMessage);
        socket.on('user-connection-status', handleUserConnectionStatus);
        socket.on('notification', handleNotification);
        socket.on('question-solved', handleNotification)

        return () => {
            // Clean up the event listeners when the component unmounts
            socket.off('message', handleSocketMessage);
            socket.off('user-connection-status', handleUserConnectionStatus);
            socket.off('notification', handleNotification);
            socket.off('question-solved', handleNotification);
        };
    }, []);
};

export default useSocket;
