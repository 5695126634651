import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { api_url } from '../../config';
import axios from 'axios';



var Testing = () => {
  const [loginform, form] = useState('OOPS!')
  const navigate = useNavigate();


  const getPoints = async () => {
    axios
      .get(api_url + '/verify', {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.message === "hello") {
            form(res.data.ip)
          }
          else {
            navigate(`/`)
          }
        }
      });
  }

  useEffect(() => {
    getPoints();
  }, []);
  const htmlString = "Hey there <br/> I hope you are doing good !!"

  return (
    <div>
      <h1>{loginform}</h1>
      {/* <span dangerouslySetInnerHTML={{ __html: htmlString }}></span> */}
    </div>
  )
}

export default Testing
