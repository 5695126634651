import { Html } from '@react-three/drei'
import React from 'react'
import styles from './Jail.module.css'

export default function JailHints({qHint}) {
  return (
    <group>


<mesh position={[-3.4,2.01,2.5]}  >
            <Html distanceFactor={0.5} position={[1.1, -0.5, 0.08]} transform rotation={[0,0,-0.3]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(1)}>
                <div className={styles.QsDiv} style={{ width: "80px", height: "100px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          <mesh position={[-2.18,1.2,2.45]}  >
            <Html distanceFactor={0.5} position={[1.1, -0.5, 0.08]} transform rotation={[-1.57,0,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(2)}>
                <div className={styles.QsDiv} style={{ width: "80px", height: "100px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          <mesh position={[-1.55,1.2,0.625]}  >
            <Html distanceFactor={0.5} position={[1.1, -0.5, 0.08]} transform rotation={[-1.57,0,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(3)}>
                <div className={styles.QsDiv} style={{ width: "150px", height: "150px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          <mesh position={[-2.97,0.5,2.48]}  >
            <Html distanceFactor={0.5} position={[1.1, -0.5, 0.08]} transform rotation={[-1.57,0,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(4)}>
                <div className={styles.QsDiv} style={{ width: "80px", height: "100px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          <mesh position={[-5,1.25,1]}  >
            <Html distanceFactor={0.5} position={[1.0, -0.5, 0.08]} transform rotation={[-1.57,0,0.75]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(5)}>
                <div className={styles.QsDiv} style={{ width: "80px", height: "100px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          
    </group>
  )
}
