import { Canvas } from "@react-three/fiber";
import { Stats, OrbitControls, PerspectiveCamera, Html, Environment } from '@react-three/drei'
import { Suspense } from "react";
import { Underground_bunker } from "./Underground_bunker";
// import { AwesomeButton } from "react-awesome-button";
import { useState } from "react";
import Loader from "./Components/Loader"
import EscButton from "./Components/HotkeyPopups/EscButton";
import OnPressLB from "./Components/HotkeyPopups/Leaderboard";
import OnPressProfile from "./Components/HotkeyPopups/Profile";
import QAlreadyS from "./Components/QAlreadySpopup";
import QuestionPopup from "./Components/QnAPopup";
import Hint from "./Components/Hint";
import OnPressRuleB from "./Components/RuleBook/Rulebook"
import { useEffect } from "react";
import { api_url } from "../../config";
import axios from 'axios';
import styles from './Components/CSS/QnA.module.css'
import Error from "../../Pages/404/Error";
import Terminal from "../../Partials/terminaldev/Terminal";

export default function Bunker() {

    const [Q, setQ] = useState({})
    const [showQ, setShowQ] = useState(false)
    const [alreadyS, setAlreadyS] = useState(false)

    const [Haddress, setHaddress] = useState(0)
    const [hint, setHint] = useState(false)



    const [bunker, setBunker] = useState(false)
    const [message, setMessage] = useState('')
    
    const [dispErr, setDispErr] = useState(false)
    const [loading, setLoader] = useState(false)
    
    
    useEffect(() => {
        setLoader(true);
        axios
            .get(api_url + '/round/first', {
                withCredentials: true,
            })
            .then((res) => {
                setLoader(false);
                let data=res.data 
                if (data.status) {
                    setTimeout(() => {
                        setBunker(true)
                    }, 6000);
                }
                else {
                    if(res.data.message){
                        setMessage(res.data.message)
                        setDispErr(true)
                    }
                    
                    else{
                        setMessage(res.data)
                        setDispErr(true)
                    }
                }
            });
    }, []);


    return (

        dispErr?<Error message = {message}/>:
        
        !bunker?<Terminal content={"npm i start <br>Welcome to the <b>Bunker</b>.... <br> <br>Please Wait Loading......"} />:


        <div style={{ width: "100vw", height: "100vh" }} >
            <ul className="notifications"></ul>
            {bunker &&(<>
                <Canvas dpr={[1, 2]}>
            <fog attach="fog" args={['white', 0, 500]} />

            <Environment files={"/dikhololo_night_1k.hdr"} background blur={0.5} far={300} />
            {/* <Environment preset={"night"} background blur={0.5} far={300} /> */}

                <PerspectiveCamera makeDefault position={[0, 0, 0]} fov={60} zoom={0.8} />
                {/* <FirstPersonControls  movementSpeed={10} /> */}
                {/* <PointerLockControls /> */}
                <OrbitControls target={[-1, 0.5, 2.5]} maxDistance={0.1} enableZoom={true} zoomSpeed={10} maxZoom={500} />
                {/* <OrthographicCamera  makeDefault position={[0.1, 10, 4]} fov={60} zoom={20  } /> */}
                <ambientLight intensity={0.1} castShadow />
                {/* <hemisphereLight castShadow/>             */}
                {/* <pointLight color={"lightgreen"} intensity={0.4} position={[0.657, 0, 0]} /> */}
                {/* <pointLight color={"green"} intensity={0.3}  castShadow distance={10} position={[20.657, 200.553, 130.855]} /> */}

                <directionalLight intensity={0.8} castShadow position={[40, 40, 150]} shadow-mapSize={[1024, 1024]}>
                    <orthographicCamera attach="shadow-camera" args={[20, 50, 10, 20]} />
                </directionalLight>

                    <Suspense >
                        <Underground_bunker
                            setQ={(e) => setQ(e)}
                            setShowQ={() => setShowQ(!showQ)}
                            setAlreadyS={() => setAlreadyS(!alreadyS)}

                            HintAddress={(e) => { setHaddress(e); setHint(!hint) }}
                        />


                    </Suspense>

                </Canvas>

            <OnPressRuleB />
            <EscButton />
            <OnPressLB />
            <OnPressProfile />

            {alreadyS && (
                <>
                    <QAlreadyS func={() => setAlreadyS(!alreadyS)} />
                </>
            )}

            {showQ && (
                <>
                    <QuestionPopup Question={Q} func={() => setShowQ(!showQ)} />
                </>
            )}

            {hint && (
                <>
                    <Hint HintAddress={Haddress} func={() => setHint(!hint)} />
                </>
            )}
            </>)}

           
            
            {loading && (
            <div className={styles.Loader} >
              <div className={styles.loading} style={{marginTop:"70vh"}}></div>
            </div>
            )}

        </div>
        )
}
