import axios from 'axios';
import './terminal.css'
import React, { useEffect, useState ,useRef} from "react";
import data from '../../Pages/Game/rulebook/data';
import { DateTime } from 'luxon';
import { api_url } from '../../config';
import { useNavigate } from 'react-router-dom';

const TerminalComponent = (props) => {
  
  const navigate=useNavigate();
  
  const [text, setText] = useState("");
  const [timerSeconds, setTimerSeconds] = useState(1000000000000000000);
  const animationStarted = useRef(false);
  const timerElementRef = useRef(null);
  const[timer,setTimer]=useState(false);
  const[enter,setEnter]=useState(false);

  const[allow,setAllow]=useState(true)

  const[startTime,setStartTime]=useState(0)

  const[err,setDispErr]=useState(false)
  const[lock,setLock]=useState(true)

  let round=props.round;

  useEffect(()=>{
    // console.log(round)
    const url = api_url + '/model/round';
    axios.post(url,{Round:round},{
      withCredentials:true
    })
    .then((res) => {
      if (res.data.status) {
          setStartTime(res.data.StartTime)
          // console.log(res.data.StartTime)

      }
      else {
          setDispErr(true)
      }
    })
    // console.log(startTime)
        
 

  },[err])


  useEffect(() => {
    const temp = new Date(startTime);
    const targetDate = DateTime.fromISO(temp.toISOString(), { zone: 'utc' });
    const currentDate = DateTime.local();
    const timeDifference = targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds / 1000;
    setTimerSeconds(timeDifference);
  }, [startTime]);



  useEffect(() => {

  





    
    let devTypeText ='You are not allowed to access it....' 

    if(round===1){
      devTypeText=data[0].R1
    }
    else if(round===2){
      devTypeText=data[0].R2
    }
    else if(round===3){
      devTypeText=data[0].R3
    }
    else if(round===4){
      devTypeText=data[0].R4
    }








    let i = 0,
      isTag,
      currentText = "";

    function type() {
      currentText = devTypeText.slice(0, ++i);

      if (currentText === devTypeText) {
        if(!err){
          setAllow(false)
          setText(currentText + "<br/><br/><span id='timer'></span><br/>");
          startTimer();
        }
        
        return;
      }

      setText(currentText + `<span class='blinker'>&#32;</span>`);
      const char = currentText.slice(-1);
      if (char === "<") isTag = true;
      if (char === ">") isTag = false;
      if (isTag) return type();
      setTimeout(type, 60);
    }




    function startTimer() {
      const timerInterval = setInterval(() => {
        
        if(!err){
          setTimer(true)
        }
        
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    }

    if (!animationStarted.current) {
      animationStarted.current = true;
      type();
    }
  }, []);









  useEffect(() => {

    

    if (timerElementRef.current) {
      const minutes = Math.floor(timerSeconds / 60);
      const seconds = (timerSeconds % 60).toFixed(0);
      const timerText = `Time Remaining : ${minutes}m ${seconds}s`;
      timerElementRef.current.textContent = timerText;

      if (timerSeconds === 0) {
        
        setTimer(false)
        if(!err){
          // setText((prevText) => prevText + "<br/><br/>Press Enter to Continue ");
        }
      }
    }
      if(timerSeconds<0 &&timerSeconds>-160000000 ){
        setTimer(false)
        setEnter(true)
        setLock(false)
      }
  }, [timerSeconds,lock,timer,enter]);


  // if(timerSeconds<0){
  //   if(allow){
  //     setAllow(false)

  //   }
  // }
  // let lsd=false;
  // if(timerSeconds>0){
  //     lsd=true;
  // }










  useEffect(() => {

    const handleKeyDown = (event) => {
      if(!lock && !err ){
          if (event.key === "Enter") {
          
          if(round===1){
            navigate('/game/round1')
          }
          if(round===2){
            navigate('/game/round2')
          }
          if(round===3){
            navigate('/game/round3')
          }
          if(round===4){
            navigate('/game/round4')
          }
          
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [lock]);





  return (
    <div className="wrapper">
    <div className="page">
      <div className="titlebar">
        <div className="dot close"></div>
        <div className="dot minimise"></div>
        <div className="dot maximise"></div>
      </div>
      <div className="terminal-container">
        
        <div className='contentT'>

        <span  dangerouslySetInnerHTML={{ __html: text }}></span>

        {(timer&&!err)&&
        <span ref={timerElementRef} id="timer"></span>
      }
        {(enter&&!err)&&
        <span><br/><br/>Press Enter to Continue </span>
      }
        <span className="blinker">&#32;</span>
        </div>
      

        {/* Shortcut keys */}
        <div className='shortcuts'>
          <h1>Shift + R : RuleBook  </h1>
          <h1>Shift + L : LeaderBoard  </h1>
        </div>           
        <div className='shortcuts'>
          <h1 style={{marginLeft:"-5%"}}>Shift + P : Profile  </h1>
          <h1>Esc : RuleBook  </h1>
        </div>           
      
      
      
      </div>
    </div>
    </div>
  );
};

export default TerminalComponent;
