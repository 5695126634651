import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api_url } from '../../../config';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../Partials/sidebar/Sidebar';
import Topbar from '../../../Partials/topbar/Topbar';
import './Member.css'

const Members = () => {
    const[res,setRes]=useState([])
    const[point,setPoint]=useState([])
    const navigate=useNavigate()


    const getPoints = async () => {
        axios
            .get(api_url + '/verify', {
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.status) {
                    setPoint(res.data)
                } else {
                    navigate(`/login`);
                }
            });
    }

    const getMembers = async () => {
        axios
            .get(api_url + '/verify/members', {
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.status) {
                    setRes(res.data.members)
                }
            });
    }
    useEffect(()=>{
        getPoints();
        getMembers();
    },[])


  return (
   <div className='wrapper cmapbg'>
            <ul className="notifications"></ul>
            <Sidebar data="6" />
            <div className='content' style={{marginTop:"-8%"}}>
                <Topbar res={point} />


                <div className="leaderboardcont">
                    {res.map((user, index) => {
                        if(user.Role==="Leader"){
                        return(
                            <div className="member">
                                <div style={{width:"50%",marginLeft:"30px"}}>
                                    <h5 className="Name">{user.Name}</h5>
                                    <h5 className="role">Leader</h5>
                                </div>
                                <h5 className="discord">DiscordID : {user.Discord}</h5>
                            </div>
                            )
                    }
                    else{

                        return(
                            <div className="member">
                                <h5 className="Name mem">{user.Name}</h5>
                                <h5 className="discord memdis">DiscordID : {user.Discord}</h5>
                            </div>
                        )
                    }
                    })}

                </div>

            </div>
        </div>
  )
}

export default Members
