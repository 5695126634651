import React, { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";

export default function Terminal({ content, button , onClick }) {
  // var [audioObject,setAudioObject] = useState(new Audio("/terminal.mp3"));

  // useEffect(() => {

  //      var playResult = audioObject.play();
  //      audioObject.addEventListener('ended', function() {
  //       this.currentTime = 0;
  //       playResult = this.play();
  //   }, false);
  //       playResult.catch(e => {
  //           console.log(e);
  //       });

  //   return () => {
  //     audioObject.pause();
  //     audioObject.removeEventListener('ended', function() { });
  //   }
  // }
  //   , []);

  return (
    <div style={{ margin: "5vw", color: "lightgreen", fontSize: "20px" }}>
      <pre>
        <Typewriter
          options={{
            delay: 20,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(content)
              .callFunction(() => {
                // audioObject.pause();
              })
              .pauseFor(2500)
              .callFunction(() => {
              })
              .start();
          }}
        />
      </pre>

      {button && onClick &&(
        <button
        onClick={()=> onClick()}
          style={{
            backgroundColor: "black",
            color: "lightgreen",
            border: "none",
            borderRadius: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          {button}
        </button>
      )}
    </div>
  );
}
