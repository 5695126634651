import React, { useEffect, useState } from 'react'
import { api_url } from '../../../config';
import styles from './CSS/QnA.module.css'
import axios from "axios";
import classNames from "classnames"
import Toast from '../../../Partials/notification/toastComponent';
import ReactDOM from 'react-dom';
import { Center } from '@react-three/drei';

const QuestionPopup = (props) => {
  const [press, setPress] = useState(true);

  const [reply, setReply] = useState(true)

  const [background, setBackground] = useState()

  const [loading, setLoader] = useState(false)
  const [form, setForm] = useState(true)


  const [finale, setFinale] = useState(false)
  const [qsns, setQsns] = useState({})
  const [qno, setQno] = useState(91)


  const [data, setData] = useState({
    Answer: ""
  })

  const Question = props.Question;
  const getQsns = () => {
    axios
      .get(api_url + '/round/fourth', {
        withCredentials: true,
      })
      .then((res) => {
        let data = res.data;
        if (data.status) {
          setQsns(data.Questions);
        }
      })
  }

  useEffect(() => {
    getQsns();
  }, [])

  function submit(e) {
    setLoader(true);
    setForm(false)
    e.preventDefault();
    let url = api_url + '/question/answer'
    axios.post(url, {
      QuestionNumber: Question.QuestionNumber,
      Answer: data.Answer
    }, { withCredentials: true })
      .then(res => {
        setLoader(false);
        if (res.data.status) {
          //setReply(true)
          // createToast(res.data.message, 'success');
          //   qsn(1)
          setForm(true)
          // setBackground('rgba(53, 158, 4, 1)')
        }
        else {
          //setReply(true)
          createToast(res.data.message, 'error');
          setForm(true)
        }
      })



  }




  const createToast = (message, type, duration = 5000) => {
    const toastContainer = document.querySelector('.notifications');
    if (toastContainer) {
      const toast = <Toast key={Date.now()} message={message} type={type} duration={duration} />;
      ReactDOM.render(toast, toastContainer);
    }
  };


  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    // console.log(newdata);
    setData(newdata);
  }




  return (
    <div>
      {press && (
        <div  >

          <div className={styles.popup} style={{ marginTop: finale ? "25vh" : "14vh" }}>
            <i className={classNames("fas", "fa-times", styles.popupClose)} onClick={() => props.func()}></i>



            {!finale && (<>
              <h2 className={styles.popupHeading}>QUESTION</h2>
                <p className={styles.popupQuestion}>
                  {Question.Question}
                <br/>
                <p className={styles.popupDescription}   dangerouslySetInnerHTML={{ __html: Question.Description }}>
                </p>
              </p>





              {form && (
                <form onSubmit={(e) => submit(e)} style={{ width: "88%" }}>
                  <input type="text" className={styles.popupInput} onChange={(e) => handle(e)} placeholder="Answer" value={data.Answer} autoComplete='off' id="Answer" required />
                  <button className={styles.popupSubmitButton} type="submit">Submit</button>
                </form>)}

              {loading && (<div className={styles.Loader}>
                <div className={styles.loading}></div>
              </div>
              )}
            </>
            )}














            {finale && (
              <>
                <h2 className={styles.popupHeading} style={{ color: "rgba(53, 158, 4, 1)", margin: "20% 0 20% 0" }}>{`Congratulations, You are the winner of Heist !!`}
                </h2>
              </>
            )}


          </div>
        </div>
      )}




    </div>
  )
}

export default QuestionPopup
