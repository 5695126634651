import { Canvas } from "@react-three/fiber";
import { Stats, OrbitControls, PerspectiveCamera, Html, Environment, PointerLockControls, RandomizedLight } from '@react-three/drei'
import { Suspense } from "react";
// import { AwesomeButton } from "react-awesome-button";
import { useState } from "react";
import { FinalRoom } from "./Finalroomfinal";
import EscButton from "./Components/HotkeyPopups/EscButton";
import OnPressLB from "./Components/HotkeyPopups/Leaderboard";
import OnPressProfile from "./Components/HotkeyPopups/Profile";
import QAlreadyS from "./Components/QAlreadySpopup";
import QuestionPopup from "./Components/QnAPopup";
import Hint from "./Components/Hint";
import OnPressRuleB from "./Components/RuleBook/Rulebook"
import { useEffect } from "react";
import { api_url } from "../../config";
import axios from 'axios';
import styles from './Components/CSS/QnA.module.css'
import Error from "../../Pages/404/Error";
import Terminal from "../../Partials/terminaldev/Terminal";

export default function FinalRoomModal() {

    const [Q, setQ] = useState({})
    const [showQ, setShowQ] = useState(false)
    const [alreadyS, setAlreadyS] = useState(false)

    const [Haddress, setHaddress] = useState(0)
    const [hint, setHint] = useState(false)



    const [bunker, setBunker] = useState(false)
    const [message, setMessage] = useState('')
    
    const [dispErr, setDispErr] = useState(false)
    const [loading, setLoader] = useState(false)
    
    
    useEffect(() => {
        setLoader(true);
        axios
            .get(api_url + '/round/fourth', {
                withCredentials: true,
            })
            .then((res) => {
                 setLoader(false);
                let data=res.data 
                if (data.status) {
                    setTimeout(() => {
                        setBunker(true)
                    }, 6000);
                }
                else {
                    if (res.data.message) {
                        setMessage(res.data.message)
                        setDispErr(true)
                    }

                    else {
                        setMessage(res.data)
                        setDispErr(true)
                    }
                }
            });
    }, []);


    return (
        dispErr ? <Error message={message} /> :


        !bunker?<Terminal content={"npm i start </br>Welcome to the Final Showdown !!</br>Ready to steal the <b>Tassaract</b>.. <br> <br>Please Wait Loading......"} />:

        <div style={{ width: "100vw", height: "100vh" }}>
            <ul className="notifications"></ul>
             {bunker &&(<>
            <Canvas dpr={[1, 2]}>
            {/* <Environment files="./pedestrian_overpass_1k.hdr"   background blur={0.5} far={300} preset="night" /> */}

                <PerspectiveCamera makeDefault position={[0,0,0]} fov={60} zoom={0.8} />
                {/* <FirstPersonControls  movementSpeed={10} /> */}
                {/* <PointerLockControls /> */}
                <OrbitControls target={[60, 50, 1.2]} maxDistance={0.1} enableZoom={true} zoomSpeed={10} maxZoom={500} />
                {/* <OrthographicCamera  makeDefault position={[0.1, 10, 4]} fov={60} zoom={20  } /> */}
                <ambientLight color={"red"} intensity={0.02} castShadow />
                {/* <hemisphereLight castShadow/>             */}
                <pointLight color={"blue"} intensity={0.05} position={[0.657, 0, 0]} />
                <pointLight color={"white"} intensity={0.05} position={[0.657, 0, 0]} />
                {/* <pointLight color={"green"} intensity={0.3}  castShadow distance={10} position={[20.657, 200.553, 130.855]} /> */}

                {/* <directionalLight intensity={0.5} castShadow position={[0, 0, 0]} shadow-mapSize={[5024, 5024]} >
                    <orthographicCamera attach="shadow-camera" args={[50, 80, 10, 20]} />
                </directionalLight> */}

                {/* <RandomizedLight /> */}
                <Suspense >
                    <FinalRoom 
                        setQ={(e) => setQ(e)}
                        setShowQ={() => setShowQ(!showQ)}
                        setAlreadyS={() => setAlreadyS(!alreadyS)}
    
                        HintAddress={(e) => { setHaddress(e); setHint(!hint) }}
                    />
                    {/* <Environment preset="sunset"> </Environment> */}

                </Suspense>

            </Canvas>
            <OnPressRuleB />
            <EscButton />
            <OnPressLB />
            <OnPressProfile />

            {alreadyS && (
                <>
                    <QAlreadyS func={() => setAlreadyS(!alreadyS)} />
                </>
            )}

            {showQ && (
                <>
                    <QuestionPopup Question={Q} func={() => setShowQ(!showQ)} />
                </>
            )}

            {hint && (
                <>
                    <Hint HintAddress={Haddress} func={() => setHint(!hint)} />
                </>
            )}
            </>)}

            {dispErr && <center><div className="error">{message}</div></center>}
            
            {loading && (
            <div className={styles.Loader} >
              <div className={styles.loading} style={{marginTop:"70vh"}}></div>
            </div>
            )}

        </div>)
}
