import React, { useEffect, useState } from 'react'
import { api_url } from '../../../../config';
import styles from '../CSS/LeaderBoard.module.css'
import axios from 'axios';

const OnPressLB = (props) => {
    const[press,setPress]=useState(false);
    
    const[team,setTeam]=useState({});
    const[LB,setLB]=useState({});
    

    const fetch =()=>{
        axios
        .get(api_url+'/model/popup/LB',{
            withCredentials:true,
        })
        .then((res)=>{
                  if(res.data.status){
                      setLB(res.data.LB);
                      currentTeam(res.data.LB,res.data.TeamName);
                    }
            })
    }

    useEffect(()=>{
        fetch();
    },[])



    const currentTeam= (LB,TeamName)=>{
        LB.map((e,x)=>{
            if(e.TeamName===TeamName){
                e.Rank=x+1;
                setTeam(e);
            }
        })
    }



      

    useEffect(() => {
        const onKeyDown = (e) => {
            if ((e.shiftKey===true) && (e.key === 'L'||e.key === 'l')){
                fetch();
                setPress(true)
            }
        }
        const onKeyUp = (e) => {
            // on purpose (only one key in condition)
            if ((e.key === 'L'||e.key === 'l') ){
                setPress(false)

                // props.buttonVisibility();
            }
        }
        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
        }
    }, [])

  



    
return (
    <div>
      {press &&
        <div className={styles.popup} style={{marginTop:"9%",paddingBottom:"2vh"}}>
            <h2 className={styles.popupHeading} style={{ marginTop: '6vh' }}>LeaderBoard</h2>

        <div className={styles.content} style={{marginBottom:"3vh"}}>
          
          {/* Current Team */}
          
                <div className={styles.team} style={{width:"92%",margin:"6% 0 -3% 4%",background:"rgba(4, 90, 18, 0.251)"}}>
                    <h5 className={styles.rank}>{team.Rank}</h5>
                    <h5 className={styles.teamname}>{team.TeamName}</h5>
                    <h5 className={styles.pts}>{team.Points}</h5>
                    <h5 className={styles.time}>{team.TotalTime}</h5>
                </div>
               

            <div className={styles.leaderboardcont} style={{paddingTop:"1vh",height:"38vh",marginLeft:"5%"}}>
            {LB.map((e,i)=>{
                if(i<5){
                    return(<div className={styles.team}>
                        <h5 className={styles.rank}>{i+1}</h5>
                        <h5 className={styles.teamname}>{e.TeamName}</h5>
                        <h5 className={styles.pts}>{e.Points}</h5>
                        <h5 className={styles.time}>{e.TotalTime}</h5>
                    </div>)
                }
            })}
            </div>

        </div>
    </div>
    }
    </div>
  )
}

export default OnPressLB
