import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../../../Partials/sidebar/Sidebar'
import { useNavigate } from 'react-router-dom';
import './play.css'
import Topbar from '../../../Partials/topbar/Topbar'

import { UilShieldCheck } from '@iconscout/react-unicons'
import { UilLock } from '@iconscout/react-unicons'
import { api_url } from '../../../config'
import useSound from 'use-sound';
import blink from './blink.mp3'
import ReactDOM from 'react-dom';
import Toast from '../../../Partials/notification/toastComponent';
import useLeaderboardSocket from '../../../leaderboardSocket';
import { DateTime } from 'luxon';



export default function Play(props) {

  const navigate = useNavigate();
  const [loading, setLoader] = useState(false)
  const [disp, display] = useState(true)
  var [bg, setbg] = useState("cmapbg")
  const [play] = useSound(blink, {volume: 1});
  const [res, setRes] = useState('')

  const getPoints = async () => {
    
    axios
      .get(api_url + '/verify', {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.status) {
          setRes(res.data)
        } else {
         // navigate(`/`);
        }
      });
  }

  useEffect(() => {
    getPoints();
  }, []);






  const createToast = (message, type, duration = 5000) => {
    const toastContainer = document.querySelector('.notifications');
    if (toastContainer) {
      const toast = <Toast key={Date.now()} message={message} type={type} duration={duration} />;
      ReactDOM.render(toast, toastContainer);
    }
  };

  function lvl1in(){
    setbg(bg + " bgblur");
    play();
    setTimeout(() => {setbg("bunkerbg")}, 50);
  }

  function lvl2in(){
    setbg(bg + " bgblur")
    play();
    setTimeout(() => {setbg("csbg")}, 50);
  }

  function lvl3in(){
    setbg(bg + " bgblur")
    play();
    setTimeout(() => {setbg("jailbg")}, 50);

  }

  function lvl4in(){
    setbg(bg + " bgblur")
    play();
    setTimeout(() => {setbg("vaultbg")}, 50);
  }

  function mout(){
    if(bg != "cmapbg"){
      setbg(bg + " bgblur")
      setTimeout(() => {setbg("cmapbg")}, 50);
    }
  }

  const handleNotification = (data) => {
    // console.log('Custom notification handler:', data);
    createToast('Info:'+data, 'info');
    // Handle the received notification in a custom way
  };
  const handleLeaderboardUpdate=()=>{
      getPoints();
  };
 useLeaderboardSocket(handleNotification,handleLeaderboardUpdate)




 const [nextRound, setNextRound] = useState()
 const [start, setstart] = useState()
 const [end, setEnd] = useState()
 const [cond, setcond] = useState(true)

  const getRound=()=>{
    axios
      .get(api_url + '/verify/start', {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.status) {
          setNextRound(res.data.Round)
          setstart(res.data.StartTime)
          setEnd(res.data.EndTime)
        } else {
          navigate(`/login`);
        }
      });
  }


  useEffect(()=>{
    getRound();
  },[])




  // let [round,setRound] = useState(0);
  //5mins before web socket trigger

  let round;



  const calculateDiff=()=>{
    const targetDate = DateTime.fromISO(start, { zone: 'utc' })
    const currentDate = DateTime.local();
    const timeDifference = (targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds)/1000;
    return timeDifference;
  }

  const [time, setTime] = useState(calculateDiff());
  




  useEffect(()=>{
    
    const timer = setInterval(() => {
      setTime(calculateDiff());
    }, 1000);
    
    
    getRound();
    
    
   











    return () => {
      clearInterval(timer);
    }
    
  },[start])
 
  
  // console.log(time,DateTime.fromISO(end, { zone: 'utc' }));
  const targetDate = DateTime.fromISO(end, { zone: 'utc' })
  const currentDate = DateTime.local();
  const timeDifference = (targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds)/1000;


  if(nextRound===1){
    if(time>300){
      round=0
    }
    if(time<=300){  //5mins
      round=1
    }
  }

  if(nextRound===2){
    if(time>300){
      round=1.5
    }
    if(time<=300){  //5mins
      round=2
    }
  }

  if(nextRound===3){
    if(time>300){
      round=2.5
    }
    if(time<=300){  //5mins
      round=3
    }
  }

  if(nextRound===4){
    if(time>300){
      round=3.5
    }
    if(time<=300){  //5mins
      round=4
    }
  }






  


  return (
    <div className='overmin'>
      <ul className="notifications"></ul>
    <div className={"bgs " + bg}>
    </div>
      {disp && 
    <div className = 'wrapper2'>
        <Sidebar data = "2"/>
        <div className='content'>
            <Topbar res={res} />

          <div className='levels d-flex justify-content-evenly align-items-center' onMouseLeave={() => {mout()}}>

            <div className="levelcont" onMouseEnter={() => (lvl1in())} onClick={()=>{if(round==1){(navigate('/game/rb')); props.func(1)}}}>

              <div className={`level ${(round===1)?"activelvl":""}`} >                
                {(round>1)&&(
                  <>
                    <UilShieldCheck size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br />BUNKER</h5>
                  </>
                )}
                
                {(round<1)&&(
                  <>
                    <UilLock size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br />BUNKER</h5>
                  </>
                )}
                
                {(round===1)&&(
                  <>
                    <h3 className='text-center'>THE<br />BUNKER</h3>
                  </>
                )}

                
                
              </div>
            </div>



            <div className="levelcont" onMouseEnter={() => (lvl2in())}   onClick={()=>{if(round==2){(navigate('/game/rb')); props.func(2) }}}>
            <div className={`level ${(round===2)?"activelvl":""}`}>
                
                {(round>2)&&(
                  <>
                    <UilShieldCheck size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br/>STATION</h5>
                  </>
                )}
                

                {(round<2)&&(
                  <>
                    <UilLock size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br/>STATION</h5>
                  </>
                )}
                
                {(round===2)&&(
                  <>
                    <h3 className='text-center'>THE<br/>STATION</h3>
                  </>
                )}
              </div>
            </div>




            <div className="levelcont" onMouseEnter={() => (lvl3in())} onClick={()=>{if(round==3){(navigate('/game/rb')); props.func(3)}}}>
            <div className={`level ${(round===3)?"activelvl":""}`}>
                
                {(round>3)&&(
                  <>
                    <UilShieldCheck size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br />SETBACK</h5>
                  </>
                )}
                

                {(round<3)&&(
                  <>
                    <UilLock size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br />SETBACK</h5>
                  </>
                )}

                {(round===3)&&(
                  <>
                    <h3 className='text-center'>THE<br />SETBACK</h3>
                  </>
                )}
              </div>
            </div>




            <div className="levelcont" onMouseEnter={() => (lvl4in())} onClick={()=>{if(round==4){(navigate('/game/rb')); props.func(4)}}}>
            <div className={`level ${(round===4)?"activelvl":""}`}>
                
                {(round>4)&&(
                  <>
                    <UilShieldCheck size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br />VAULT</h5>
                  </>
                )}
                

                {(round<4)&&(
                  <>
                    <UilLock size="70"/>
                    <hr className='seperator' />
                    <h5 className='text-center'>THE<br />VAULT</h5>
                  </>
                )}

                {(round===4)&&(
                  <>
                    <h3 className='text-center'>THE<br />VAULT</h3>
                  </>
                )}             
                 </div>
            </div>
            
          </div>
        </div>
    </div> }
      {loading && <div className='Loader'>
        <div className="loading"></div>
      </div>
      }
    </div>
    
  )
}
