import React from 'react'

let data=[{
    R1:`npm i start <br/>[From Team Raptus]<br/>
    <br/><br/>In the murky depths of the internet, rumours spread like wildfire about a quantum computer called Taserect whose immense potential and repository of knowledge hold the allure of unparalleled power. <br/><br/>

The whispers echoed across the hacker community, capturing the imagination of Alex, a daring and gifted hacker. The allure of gaining access to all the world's technological secrets proved too irresistible for him to ignore.<br/><br/>

With a newfound determination, Alex hatched a plan to make Taserect his ultimate prize. He polished his unique skills crucial for the heist and delved deeper into the enigmatic Taserect's existence, analyzing the leaked information and devising strategies.<br/>
`,
    R2:`npm i start <br/>[From Team Raptus]<br/>
    <br/><br/>His first task was infiltrating a heavily guarded control room, where he believed he could obtain the location and digital key needed to reach Taserect. His plan involved a delicate dance of subterfuge, blending their technical prowess with cunning finesse.<br/><br/>

Under the cover of darkness, they crept through the facility's shadows, bypassing security systems and eluding patrolling guards. The control room was like a fortress, but Alex's meticulous planning paid off. He hacked into the control room's mainframe, disabling security cameras and alarms.<br/><br/>

With each step forward, his excitement and anticipation grew. As the final firewall fell, he secured the critical information they sought—the location and digital key to the quantum treasure, Taserect.<br/>
`,
    R3:`npm i start <br/>[From Team Raptus]<br/>
    <br/><br/>In the aftermath of their seemingly triumphant infiltration, his celebration turned to shock and dismay. Just as he believed he had outwitted the world's security, alarms blared, and the control room was suddenly flooded with elite agents. He found himself surrounded and overpowered by highly trained forces along with many other hackers like him.<br/><br/>

Betrayed from within or not, they couldn't determine at that moment. Regardless, the hackers were apprehended and swiftly transported to a maximum-security prison. Their dreams of accessing Taserect were now hanging by a thread, as they faced the grim reality of being locked away.<br/>
`,
    R4:`npm i start <br/>[From Team Raptus]<br/>
    <br/><br/>Locked behind bars, the Pool's camaraderie and unity grew stronger. They pooled their intellect, working together to solve intricate puzzles, hack prison systems, and navigate through impenetrable encryption barriers.<br/><br/>

In their shared struggles and triumphs, bonds were forged that transcended the prison's confines. Their collective efforts bore fruit as they gradually mapped out a daring escape plan. Months of meticulous planning and preparation culminated in a daring jailbreak, leaving astonished guards in their wake.<br/><br/>

Free once more, they embarked on the final leg of their journey—stealing Taserect.<br/><br/>

Following the breadcrumbs of hidden clues, they navigated a labyrinth of challenges and adversities, leading them to the heavily fortified facility concealing the elusive quantum computer.<br/>
`,
}]

export default data
