import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/login/Login';
import Rulebook from "./Pages/Game/rulebook/Rulebook";
import Leaderboard from "./Pages/Game/leaderboard/Leaderboard";
import Bunker from "./Models/Bunker/Bunker";
import useSocket from "./useSocket";
import axios from "axios";
import { api_url } from "./config";
import Play from "./Pages/Game/play/Play";
import ControlRoomModel from "./Models/Control Room/ControlRoomModel";
import JailRoomModal from "./Models/Jail/JailRoomModel";
import FinalRoomModal from "./Models/XFinal/FinalRoom";
import TerminalComponent from "./Partials/terminal/terminal";
import Sponsors from "./Pages/Game/LandingPage/Components/Sponsor";
import Heropage from "./Pages/Game/LandingPage/Components/Heropage";
import RegisterNew from "./Pages/Game/LandingPage/Landing";
import Error from "./Pages/404/Error";
import Members from "./Pages/Game/Members/Members";
import Test from "./Pages/others/Test";
import Redirect1 from "./Pages/others/Redirect1";
import KeyRaptusComponent from "./Pages/key";


function App() {
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = api_url;


 

  const [round,setRound]=useState(0)


  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}  ></Route>
          <Route path="/rulebook" element={<Rulebook /> }></Route>
          <Route path="/leaderboard" element={<Leaderboard />}></Route>
          <Route path="/game" element={ <Play func={(i)=>{setRound(i)}} />}></Route>
          <Route path="/game/rb" element={<TerminalComponent round={round} />}></Route>
          
          <Route path="/members" element={<Members />}></Route>
          
          <Route path="/" element={<RegisterNew />}></Route>
          
          

          <Route path="/game/round1" element={<Bunker />}></Route>
          <Route path="/game/round2" element={<ControlRoomModel />}></Route>
          <Route path="/game/round3" element={<JailRoomModal />}></Route>
          <Route path="/game/round4" element={<FinalRoomModal />}></Route>

          
          <Route path="/game" element={<Play/>}></Route>


          <Route path="/key" element={<Test />}></Route>
        
          <Route path="/theanswerwasnthere" element={<KeyRaptusComponent/>}></Route>
          <Route path="/goodluck" Component={() => {
            window.open("https://replit.com/@PanchamAgarwal/slashgoodluck");
            return null;
          }}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
