import React, { useEffect, useState } from 'react'
import { api_url } from '../../../../config';
import styles from '../CSS/Profile.module.css'
import { DateTime } from 'luxon';
import axios from 'axios';

const OnPressProfile = (props) => {
    const[press,setPress]=useState(false);
const[profile,setProfile]=useState({});

    
    function fetch(){
        axios
        .get(api_url+'/model/profile',{
            withCredentials:true,
        })
        .then((res)=>{
                  if(res.data.status){
                      setProfile(res.data);
                    //   console.log(res.data.RoundEnd,'     ',(new Date()).toISOString());
                  }
            })
    }

    useEffect(()=>{
        fetch();
      },[])


      

    useEffect(() => {
        const onKeyDown = (e) => {
            if ((e.shiftKey===true) && (e.key === 'P'||e.key === 'p')){
                fetch();
                setPress(true)
            }
        }
        const onKeyUp = (e) => {
            // on purpose (only one key in condition)
            if ((e.key === 'P'||e.key === 'p') ){
                setPress(false)

                // props.buttonVisibility();
            }
        }



        //Countdown
        const timer = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);
  




        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);
        return () => {
            clearInterval(timer);
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
        }
    }, [profile.RoundEnd])





    const calculateCountdown = () => {
        const targetDate = DateTime.fromISO(profile.RoundEnd, { zone: 'utc' });
        const currentDate = DateTime.local();
    
        const timeDifference = targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds;
    
        if (timeDifference <= 0) {
          return 'Round Ended';
        }
    
        if(timeDifference)
        {
        const duration = DateTime.fromMillis(timeDifference).toFormat('hh : mm : ss');
            
        return duration;}
      };

    const [countdown, setCountdown] = useState(calculateCountdown());
    
    



//props for style and colour
  
return (
    <div>
     {press &&(   
     <div className='UnivBody'>

    <div className={styles.popup} >

            
            <h2 className={styles.popupHeading} style={{ marginTop: '30px' }}>Round : {profile.Round}</h2>
            <h2 className={styles.popupHeading} style={{ marginTop: '30px' }}>{profile.TeamName}</h2>
            <h2 className={styles.popupHeading} style={{ fontFamily: 'Roboto Flex', fontSize: '15px', fontWeight: '100', marginTop: '2px', marginBottom: '30px' }}>{profile.UserName}</h2>
            <p className={styles.popupContent}>TOTAL POINTS: {profile.Points}</p>
            <p className={styles.popupContent}>QUESTIONS SOLVED: {profile.QuestionsSolved}/{profile.TotalQuestions}</p>
            <p className={styles.popupContent}>TIME REMAINING: {countdown}</p>
           
            



    </div>
    </div>)}
    </div>
  )
}

export default OnPressProfile
