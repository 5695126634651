import React, { useState } from 'react'
import Heropage from './Components/Heropage'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Sponsors from './Components/Sponsor'



const Landing = () => {
  const navigate=useNavigate();
  // function scrollDown(){
   
  //   document.getElementById("registration").scrollIntoView({
  //     block: 'start',
  //     behavior: 'smooth',
  //               inline: 'start'
  //   })
  // }
  
  return (
    <div>
    <Heropage />

    <Sponsors/>

    <div className='cont'>
    <hr className='seperator' style={{width:"40%" , marginLeft:"30%",marginBottom:"9%"}}/>
          <h1 id="registration" className="reg" style={{marginTop:"-8%"}}>RAPTUS</h1>
       <hr className='seperator' style={{width:"40%" , marginLeft:"30%",marginBottom:"9%"}}/>

            <center>
              <div className="button-container">
                <button type="button" className="join" style={{ cursor: "pointer" }} onClick={() => {navigate('/login');}}>
                  Start the HEIST
                </button>
              </div>
            </center>
    </div>

    </div>
  )
}

export default Landing
