import React, { useState, useEffect, useRef } from 'react'
import { useGLTF, useAnimations, Html } from '@react-three/drei'
import { api_url } from '../../config';
import styles from './BunkerModel.module.css'

import axios from 'axios';
import { DateTime } from 'luxon';
import useRoundSocket from '../../gameRoomSocket';
import styles2 from './Components/CSS/QnA.module.css'
import Loader from './Components/Loader';
import ModelMeshBunker from './ModelMesh';
import BunkerHints from './BunkerHints';
import ReactDOM from 'react-dom';
import Toast from '../../Partials/notification/toastComponent';
import { useNavigate } from 'react-router-dom';
import Error from '../../Pages/404/Error';

export function Underground_bunker(props) {
  
  const group = useRef()
  const [qsns, setQsns] = useState({})
  const [time, setTime] = useState('')
  const [bunker, setBunker] = useState(false)
  const [message, setMessage] = useState('')
  const [dispErr, setDispErr] = useState(false)
  const { nodes, materials, animations } = useGLTF('/underground_bunker-transformed.glb')
  const { actions, mixer } = useAnimations(animations, group);
  const [serverTime, setServerTime] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    axios
    .get(api_url + '/round/first', {
      withCredentials: true,
    })
    .then((res) => {
      
        let data = res.data;
        setLoading(false)
        if (data.status) {
          setQsns(data.Questions);
          setTime(data.RoundEnd);
          setBunker(true);
          setServerTime(data.ServerTime);
        }
        else {
          setMessage(data.message)
          setDispErr(true)
          Error(data.message);
        }
      });
  }








  useEffect(() => {
    // console.log(actions);
    if(bunker){
      actions.HatchOpening.play();
    }      

    //Countdown
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    fetchData();


    return () => {
      clearInterval(timer);
    }


  }, [bunker,time]);



  const createToast = (message, type, duration = 5000) => {
    const toastContainer = document.querySelector('.notifications');
    if (toastContainer) {
      const toast = <Toast key={Date.now()} message={message} type={type} duration={duration} />;
      ReactDOM.render(toast, toastContainer);
    }
  };



  const handleNotification = (data) => {
    //console.log('Custom notification handler:', data);
    createToast('Info:'+data, 'info');
    //alert(data)
    // Handle the received notification in a custom way
  };
  const handleRound = (data) => {
    // console.log('Custom notification handler:', data);
    
    setTimeout(()=>{
      navigate('/leaderboard')
    },3000);  // Handle the received notification in a custom way 
  }
  const handleQuestionSolved = (data) => {
    fetchData();
  }

  useRoundSocket(handleNotification, handleRound, handleQuestionSolved)


  const calculateCountdown = () => {
    const targetDate = DateTime.fromISO(time, { zone: 'utc' });
    const currentDate = DateTime.local();

    const timeDifference = targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds;

    if (timeDifference <= 0) {
      return 'Round Ended';
    }

    if(timeDifference)
    {
    const duration = DateTime.fromMillis(timeDifference).toFormat('hh : mm : ss');

    return duration;}
  };

  const [countdown, setCountdown] = useState(calculateCountdown());




  //On hint press  
  const Qhint = (num) => {



    const index=qsns.Unsolved.findIndex(obj=>obj.HintAddress===num);
    if(index===-1){
      //nothing
    }
    else{
      props.HintAddress(num);
    }
  }



const[as,setAs]=useState(false)

  const qsn = (num) => {
    
    axios
    .get(api_url + '/round/first', {
      withCredentials: true,
    })
    .then((res) => {
        let data = res.data;
        // console.log(res);
        if (data.status) {
          setAs(true)
          setQsns(data.Questions);
        }})


    //AlreadySolved Qsns
    qsns.Solved.map((e) => {
      if (e.num === num) {
        props.setAlreadyS();
      }
    })

    qsns.Unsolved.map((e,x) => {
      if (e.num == num) {
        props.setQ(e);
        props.setShowQ();
      }
    })


  }






  return (


    <group ref={group} {...props} dispose={null}>
    
      {loading && 
          <group >
            <Html position={[-2.17, 4.33, 5.89]} style={{margin:"0",padding:"0"}}>
              <div className={styles2.Loader} >
                <div className={styles2.loading} style={{marginTop:"70vh"}}></div>
              </div>
            </Html>
            {/* <Loader  position={[-0.5, 1.2, 5.89]}/> */}
          </group>
      }
      
      
      {bunker &&
        <group name="ModelScene">

       <ModelMeshBunker nodes={nodes} materials={materials} group={group} props={props} />
        <BunkerHints qHint={Qhint}/>


          <group position={[1.48, 1.48, -4.29]}   >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform
              rotation={[0, 5.48, 0]}>
              <div tabIndex={-1} className={styles.Watch} >
                {countdown}
              </div>
            </Html>
          </group>
         


          {/* 1st */}
          <mesh position={[-0.727, -0.478, -4.9]} scale={0.7} >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qsn(1)}>
                
                <div className={styles.QsDiv} style={{ width: "800px", height: "1640px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>


          {/* 2nd */}
          <mesh position={[0.34, 0.78, 2.17]} scale={0.7}>
            <Html distanceFactor={0.8} position={[2.7, 0, 0]} transform rotation={[0, 4.712, 0]} >
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qsn(2)}>
                
                <div className={styles.QsDiv} style={{ width: "2450px", height: "1100px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>


          {/* 3rd */}
          <mesh position={[-3.4, -0.07, -0.52]} scale={0.7}>
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0, 1.57, 0]} >
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qsn(3)}>
                
                <div className={styles.QsDiv} style={{ width: "580px", height: "730px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>


          {/* 4th */}
          <mesh position={[-2.3, 1.7, 1.7]} scale={0.7}>
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[1.57, 0, 3.14]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qsn(4)}>
                
                <div className={styles.QsDiv} style={{ width: "1200px", height: "700px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>



          {/* 5th */}
          <mesh position={[-1.46, 1.06, 5.89]} scale={0.7}>
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0, 3.14, 0]}>
              <>
                <button tabIndex={-1} className={styles.QsButtons} onClick={() => qsn(5)}>
                  
                  <div className={styles.QsDiv} style={{ width: "1200px", height: "700px" }}>
                    <p style={{ margin: "0 5% 0 5% " }}></p>
                  </div>
                </button>
              </>
            </Html>
          </mesh>

        

</group>
      }
      {dispErr &&
        <Html>
          <center>
            <div className='error'>{message}</div>
          </center>
        </Html>
      }

      </group>



  )
}

useGLTF.preload('/underground_bunker-transformed.glb')















// import React, { useEffect, useRef } from 'react'
// import { useGLTF, useAnimations } from '@react-three/drei'

// export function Underground_bunker(props) {
//   const group = useRef()
//   const { nodes, materials, animations } = useGLTF('/underground_bunker-transformed.glb')

//   const { actions, mixer } = useAnimations(animations, group);

//   useEffect(() => {
//     // console.log(actions.Animation);
//     //play animation from actions variable react three js
//     // mixer.start();
//     // mixer.stopAllAction();
//     // mixer.stop();
//     // mixer.clipAction(actions.Animation).play();

//     //  console.log(mixer);

//     actions.HatchOpening.play();

//   }, []);

//   return (
   
//   )
// }

// useGLTF.preload('/underground_bunker-transformed.glb')
