import { useEffect } from "react";
import { io } from 'socket.io-client';
import { api_url } from "./config";

const useLeaderboardSocket = (customNotificationHandler, customLeaderboardUpdateHandler) => {
    
    useEffect(() => {
        const socket = io(api_url, {
            transports: ['websocket'],
            withCredentials: true,
        });

        const handleNotification = async (data) => {
            console.log('Received notification:', data);
            if (customNotificationHandler) {
                customNotificationHandler(data);
            }
        };

        const handleLeaderboardUpdate = (data) => {
            console.log('Received leaderboard update:', data);
            if (customLeaderboardUpdateHandler) {
                customLeaderboardUpdateHandler(data);
            }
        };

        socket.on('notification', handleNotification);
        socket.on('leaderboard-update', handleLeaderboardUpdate);

        return () => {
            // Clean up the event listeners when the component unmounts
            socket.off('notification', handleNotification);
            socket.off('leaderboard-update', handleLeaderboardUpdate);
        };
    }, []);
};

export default useLeaderboardSocket;
