import React, { useEffect, useState } from 'react';
import './notification.css'
const Toast = ({ message, type, duration = 5000 }) => {
    const [show, setShow] = useState(true);
    // console.log("ji")
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, duration);

        return () => {
            clearTimeout(timer);
        };
    }, [duration]);

    const removeToast = () => {
        setShow(false);
    };

    const getIcon = () => {
        switch (type) {
            case 'success':
                return 'fa-circle-check';
            case 'error':
                return 'fa-circle-xmark';
            case 'warning':
                return 'fa-triangle-exclamation';
            case 'info':
                return 'fa-circle-info';
            default:
                return 'fa-circle';
        }
    };

    const getClassNames = () => {
        let classNames = 'toast';
        if (type) {
            classNames += ` ${type}`;
        }
        if (!show) {
            classNames += ' hide';
        }
        return classNames;
    };

    return (
        <li className={getClassNames()}>
            <div className="column">
                <i className={`fa-solid ${getIcon()}`}></i>
                <span>{message}</span>
            </div>
            <i className="fa-solid fa-xmark" onClick={removeToast}></i>
        </li>
    );
};

export default Toast;
