import React, { useEffect, useState } from 'react'
import styles from './CSS/QnA.module.css'
import classNames from "classnames"

const QAlreadyS = (props) => {

  
return (
    <div>
     <div className={styles.popup} style={{paddingBottom:"10%"}}>
     <i className={classNames("fas", "fa-times" ,styles.popupClose)} onClick={()=>props.func()}></i>
     <h2 className={styles.popupHeading}>
       <i className={classNames("fa-solid", "fa-circle-exclamation")} style={{ color: '#ffffff', margin:"18% 2% 0 0" }}></i>
        QUESTION ALREADY ANSWERED
     </h2>
    </div>
    </div>
  )
}

export default QAlreadyS
